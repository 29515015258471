/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

// Summary And Greeting Section

import emoji from "react-easy-emoji";

const illustration = {
  animated: true // set to false to use static SVG
};

const greeting = {
  username: "Shahid",
  title: "Shahid Kamal",
  subTitle: emoji(
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact."
  ),
  resumeLink:
    "https://drive.google.com/file/d/1mTlXvuqBXU0Yh6vBiEgwe5-xZ9ANUP-Y/view?usp=sharing",
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/shahidcodes",
  linkedin: "https://www.linkedin.com/in/shahidkamal/",
  gmail: "me@shahidkamal.ml",
  // hashnode: "https://hashnode.com/@shahidcodes",
  stackoverflow: "https://stackoverflow.com/users/3501067/shahid-kamal",
  twitter: "https://twitter.com/shahidcodes",
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "What I do?",
  subTitle: "FULL STACK DEVELOPER WHO WANTS TO EXPLORE EVERY TECH STACK",
  skills: [
    emoji(
      "⚡ Develop highly interactive Front end / User Interfaces for your web and mobile applications"
    ),
    emoji("⚡ Progressive Web Applications ( PWA ) in normal and SPA Stacks"),
    emoji(
      "⚡ Integration of third party services such as Firebase/ AWS / Digital Ocean"
    )
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "html-5",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "css3",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      skillName: "sass",
      fontAwesomeClassname: "fab fa-sass"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "reactjs",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "nodejs",
      fontAwesomeClassname: "fab fa-node"
    },
    {
      skillName: "react-native/flutter",
      fontAwesomeClassname: "fab fa-android"
    },

    {
      skillName: "npm",
      fontAwesomeClassname: "fab fa-npm"
    },
    {
      skillName: "sql/nosql",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "aws",
      fontAwesomeClassname: "fab fa-aws"
    },
    {
      skillName: "firebase",
      fontAwesomeClassname: "fas fa-fire"
    },
    {
      skillName: "python",
      fontAwesomeClassname: "fab fa-python"
    },
    {
      skillName: "docker",
      fontAwesomeClassname: "fab fa-docker"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "Jawaharlal Nehru Tech University",
      logo: require("./assets/images/jntuh.png"),
      subHeader: "Bachelor in Computer Science",
      duration: "July 2014 - June 2018",
      desc: "Graduated from one of country's most reputed university.",
      descBullets: [
        "Participated and won in multiple programming contests",
        "Built a message board and notification system for the college"
      ]
    }
  ]
};

const techStack = {
  viewSkillBars: false, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Frontend/Design", //Insert stack or technology you have experience in
      progressPercentage: "85%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Backend",
      progressPercentage: "90%"
    },
    {
      Stack: "Programming",
      progressPercentage: "95%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Co-Founder/Tech Lead",
      company: "Popway",
      companylogo: require("./assets/images/popway.png"),
      date: "May 2019 – Current",
      desc: "Popway was founded to tackle queue problem due to covid-19 by creating a virtual online queue. At popway, I turned the idea to a product. I created complete architecture, backend and android applications."
    },
    {
      role: "Full Stack Developer",
      company: "Laalsa Business Insights",
      companylogo: require("./assets/images/laalsa.png"),
      date: "Oct 2019 – Feb 2021",
      desc: "At Laalsa, I created a QR based of food ordering platform for contact-less ordering experience at Restaurants. I also scaled and enhanced existing infrastructure to support more users and more orders."
    },
    {
      role: "Full Stack Developer",
      company: "PayorCRM",
      companylogo: require("./assets/images/payorcrm.png"),
      date: "Aug 2018 – Oct 2019",
      desc: "PayorCRM is account receivables and collection management software. I created the platform from scratch."
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: true // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Big Projects",
  subtitle: "SOME STARTUPS AND COMPANIES THAT I HELPED TO CREATE THEIR TECH",
  projects: [
    {
      image: require("./assets/images/dinezy-logo.png"),
      projectName: "Dinezy - The order management app",
      projectDesc:
        "Dinezy order management platform allows your customers to order food right through their mobile devices.",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://www.dinezy.in/"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/hootwahawaat.png"),
      projectName: "Hootwahawaat",
      projectDesc:
        "Hootwahawaat is a boat booking and hiring mobile application providing marketplace for buying and selling items."
      // footerLink: [
      //   {
      //     name: "Visit Website",
      //     url: "http://nextu.se/"
      //   }
      // ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements And Certifications 🏆 "),
  subtitle:
    "Achievements, Certifications, Award Letters and Some Cool Stuff that I have done !",

  achievementsCards: [
    {
      title: "Google Code-In Finalist",
      subtitle:
        "First Pakistani to be selected as Google Code-in Finalist from 4000 students from 77 different countries.",
      image: require("./assets/images/codeInLogo.webp"),
      footerLink: [
        {
          name: "Certification",
          url: ""
        },
        {
          name: "Award Letter",
          url: ""
        },
        {
          name: "Google Code-in Blog",
          url: ""
        }
      ]
    },
    {
      title: "Google Assistant Action",
      subtitle:
        "Developed a Google Assistant Action JavaScript Guru that is available on 2 Billion devices world wide.",
      image: require("./assets/images/googleAssistantLogo.webp"),
      footerLink: [
        {
          name: "View Google Assistant Action",
          url: ""
        }
      ]
    },

    {
      title: "PWA Web App Developer",
      subtitle: "Completed Certifcation from SMIT for PWA Web App Development",
      image: require("./assets/images/pwaLogo.webp"),
      footerLink: [
        {name: "Certification", url: ""},
        {
          name: "Final Project",
          url: ""
        }
      ]
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Blogs",
  subtitle:
    "With Love for Developing cool stuff, I love to write and teach others what I have learnt.",

  blogs: [
    {
      url: "https://shahidcodes.hashnode.dev/basic-apis-testing-with-jest-and-supertest",
      title: "Basic APIs Testing with JEST and SuperTest",
      description: "Write API testing easily with JEST and SuperTest"
    },
    {
      url: "https://shahidcodes.hashnode.dev/how-to-add-x-minutes-to-a-date-in-mongodb-aggregation",
      title: "How to add x minutes to a date in MongoDB aggregation?",
      description: "Write MongoDB aggregations like Pro"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: emoji(
    "I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅"
  ),

  talks: [
    {
      title: "",
      subtitle: "",
      slides_url: "",
      event_url: ""
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [""],
  display: false // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle:
    "Discuss a project or just want to say hi? My Inbox is open for all.",
  number: "+960-7209350",
  email_address: "me@shahidkamal.ml"
};

// Twitter Section

const twitterDetails = {
  userName: "shahidcodes", //Replace "twitter" with your twitter username without @
  display: false // Set true to display this section, defaults to false
};

export {
  illustration,
  greeting,
  socialMediaLinks,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails
};
